<template>
  <Suspense>
    <template #default>
      <router-view :key="Math.random()"> </router-view>
    </template>
    <template #fallback> Loading... </template>
  </Suspense>
  <ModalTeleportComponent />
</template>

<style lang="scss"></style>
<script>
import ModalTeleportComponent from "@/components/modal/ModalTeleportComponent";
import { categoryStore } from "@/stores/CategoryStore";
import { vouchersStore } from "@/stores/VoucherStore";
import { placeStore } from "@/stores/PlaceStore";
import { searchStore } from "@/stores/SearchStore";
import { userStore } from "@/stores/UserStore";
import { langStore } from "@/stores/LangStore";

export default {
  components: { ModalTeleportComponent },
  provide: {
    searchStore,
    placeStore,
    categoryStore,
    vouchersStore,
    langStore,
    userStore
  },
  setup() {
    return {
      placeStore,
      langStore
     };
  },
};
</script>

import { computed, reactive, readonly } from "vue";
import i18n from "@/i18n";
import Marker = google.maps.Marker;


const state = reactive({
  focusedMarker: undefined,
  focusedMarkerId: undefined,
  focusedMarkerObject: undefined,
  overlay: false
});

/**
 * Set clicked marker so we can highlight the corresponding search result from the list
 *
 * @param label
 * @return void
 */
const setFocusedMarker = (label: string | undefined): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.focusedMarker = label
};
const setFocusedMarkerId = (id: number | undefined): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.focusedMarkerId = id
};
const setFocusedMarkerObject = (obj: Marker | undefined): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.focusedMarkerObject = obj
};

/**
 * Toggle full page overlay during server response
 * 
 * @param should Boolean flag to let the view show/hide overlay
 */
const showOverlay = (should: boolean): void => {
  state.overlay = should;
};

export const userStore = readonly({
  state: state,
  setters: {
    setFocusedMarker,
    showOverlay,
    setFocusedMarkerId,
    setFocusedMarkerObject
  },
  getters: {
    /**
       * Get the current selected marker object on the map if any
       * @return string|undefined
       */
    focusedMarkerObject: computed(() => state.focusedMarkerObject),
    /**
       * Get the current selected marker id on the map if any
       * @return string|undefined
       */
    focusedMarkerId: computed(() => state.focusedMarkerId),
      /**
       * Get the current selected marker on the map if any
       * @return string|undefined
       */
    focusedMarker: computed(() => state.focusedMarker),
      /**
       * Get overlay display value
       * @return boolean
       */
    overlay: computed(() => state.overlay)
  },
});

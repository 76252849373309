import { ENUMVoucherTypes } from "@/Interfaces/EnumVouchers";
import { ENUMVoucherColors } from "@/Interfaces/EnumColorVouchers";
import { computed, reactive, readonly } from "vue";
import { LocationQueryValue } from "vue-router";

const state = reactive({
  items: [
    {
      value: 1,
      ico: ENUMVoucherTypes.MEAL,
      label: "emv",
      color:ENUMVoucherColors.MEAL,
    },
    {
      value: 2,
      ico: ENUMVoucherTypes.ECO,
      label: "eco",
      color:ENUMVoucherColors.ECO,
    },
    {
      value: 3,
      ico: ENUMVoucherTypes.GIFT,
      label: "gift",
      color:ENUMVoucherColors.GIFT,
    },
    {
      value: 4,
      ico: ENUMVoucherTypes.ACTIV,
      label: "activ",
      color:ENUMVoucherColors.ACTIV,
    },
      {
      value: 5,
      ico: ENUMVoucherTypes.PPP,
      label: "ecv",
        color:ENUMVoucherColors.PPP,
    },
  ],
  selected: undefined,
  selectedlabel: undefined,
});


/**
 *
 * @param item
 */
function setSelected(value: any): void {
 state.selected = value;
}

function resetVoucher(): void {
  state.selected = undefined;
}

/**
 * Find the
 * @param value
 */
function findElement(value: any): Record<string, any> | boolean {
    if (!value) {
    return false;
  }

  if (typeof value === "string") {
    try {
      value = +value
    }
    catch (err) {
      console.log("Looking for a string, tried to convert it to number but not possible.")
    }
  }

  const found = state.items.findIndex(
    (element: any) => element.value === value
  );

  return found > -1 ? state.items[found] : false;
}

function getSelectedLabel(){
  console.log(state.selected)
  console.log(typeof state.selected)
  if (typeof state.selected == "string") {
      const selected = state.items.find(item => item.value.toString() === state.selected);
            if (selected) {
        return selected.label
      }

  }
  else {
    const selected = state.items.find(item => item.value === state.selected);
          if (selected) {
        return selected.label
      }

  }

    }

export const vouchersStore = readonly({
  state: state,
  actions: {
    resetVoucher,
    findElement,
    getSelectedLabel,
  },
  setters: {
    setSelected,
  },
  getters: {
    getSelected: computed(() => state.selected),
    getItems: computed(() => state.items),
  },
});

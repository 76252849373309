import { reactive, computed, readonly } from "vue";

const state = reactive({
  items: [],
  selected: undefined,
  allCategories: []
});


/**
 * The main entrypoint where the results would be passed to the global state
 *
 * @param categories
 */

function setCategories(categories : any): void {
  console.log('setting categories in STORE')
  state.items = categories;
}

function setSelected(category : any): void {
  console.log("setting selected to :" + category)
  state.selected = category;
}

function resetCategories(): void {
  state.items = [];
  state.selected = undefined;
}

function findElement(value: any): Record<string, any> | boolean {
  console.log("finding category: " + value)
  if (!value) {
    return false;
  }

  if (typeof value === "string") {
    try {
      value = +value
    }
    catch (err) {
      console.log("Looking for a string, tried to convert it to number but not possible.")
    }
  }

  const found = state.items.findIndex(
    (element: any) => element.value === value
  );

  return found > -1 ? state.items[found] : false;
}



export const categoryStore = readonly({
  state: state,
  setters: {
    setSelected,
    setCategories,
  },
  actions: {
    resetCategories,
    findElement
  },

  getters: {
    getItems: computed(() => state.items),
    getSelected: computed(() => state.selected),
  },
});

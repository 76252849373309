<template>
  <div id="monizze-modal-teleport">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ModalTeleportComponent",
};
</script>

export enum ENUMVoucherColors {
    //ECO : #14CC4D
    //MEAL : #F59100
    //GIFTE : #E60030
    //ACTIV : #0070B8
    //CORONA PREMIUM : #F9B517
    ECO = "#14CC4D",
    GIFT = "#E60030",
    MEAL = "#F59100",
    ACTIV = "#0070B8",
    CONSUMPTION = "#4DBFA5",
    ECV = "#4DBFA5",
    MONIZZE = "#ED9000",
    PPP = "#4DBFA5",
    // RETROPASS = "restopass",
}

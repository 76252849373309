/* eslint-disable */
import {reactive, toRefs} from "vue";

const state = reactive({
    Zoom: 8,
    Center: undefined,
    Bounds: undefined
});


export default {...toRefs(state)};

import {createRouter, createWebHistory, onBeforeRouteUpdate, RouteRecordRaw} from "vue-router";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/nl-BE",
  },
  {
    path: "/:lang",
    name: "Home",
    props: (route: any) => ({
      voucherType: route.query?.voucher_type ? parseInt(route.query?.voucher_type) : undefined,
      category: route.query?.category ? parseInt(route.query?.category) : undefined,
      lang: route.params?.lang,
    }),
    component: () =>  import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    children: [
      {
        path: "merchants/:merchant_id",
        name: "MerchantDetails",
        props: (route: any) => ({
          merchantId: parseInt(route.params.merchant_id),
        }),
        component: () => import( /* webpackChunkName: "home.merchant_details" */ "../views/MerchantDetailsPage.vue" ),
      },
    ],
  },
  {
    path: "/:lang/search/:search_type/:search_criteria",
    name: "Search",
    component: () => import(  /* webpackChunkName: "search" */ "../views/Search.vue" ),
    props: (route: any) => ({
      voucherType: route.query?.voucher_type ? parseInt(route.query?.voucher_type) : undefined,
      category: route.query?.category ? parseInt(route.query?.category) : undefined,
      searchType: route.params?.search_type || route.query?.search_type,
      searchCriteria: route.params?.search_criteria || route.query?.search_criteria || "",
      lang: route.params?.lang,
    })
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



export default router;

import { createApp } from "vue";

import App from "./components/layouts/App.vue";
import i18n from "@/i18n";
import router from "@/router/index";
import "@/styles/main.scss";
import globalStore from "@/stores/GlobalStore";
const app = createApp(App);
app.config.errorHandler = function (err, vm, info) {
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    console.log('Custom vue error handler: ', err, vm, info);
};
app.config.warnHandler = function (err, vm, info) {
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    console.log('Custom vue warn handler: ', err, vm, info);
};

app.use(i18n);
app.use(router);
app.provide('globalStore',globalStore);
app.mount("#app");

export { i18n };

import { createI18n } from "vue-i18n";
import fr from "./translations/fr.json";
import nl from "./translations/nl.json";
import en from "./translations/en.json";

const i18n = createI18n({
  locale: "nl-BE",
  mode: "composition",
  globalInjection: true,
  legacy: false,

  messages: {
    "en-BE": en,
    "fr-BE": fr,
    "nl-BE": nl,
  },
});

export default i18n;

import { computed, reactive, readonly } from "vue";

const state = reactive({
  searchType: "location",
  searchCriteria: "",
  postcodes: {},
  searched: false,
  autocompleteName: []
});

/**
 * Saving to the store currently requested search type by the user
 *
 * @param type
 * @return void
 */



const setSearchType = (type: string | undefined): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  state.searchType = type;
  console.log('setting search type to: ' + type)

};

const setAutocompleteNameArray = (names: Record<string, undefined> | undefined): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  state.autocompleteName = names;
};

/**
 * Saving to the store currently requested search criteria by the user
 * @param text
 */
const setSearchCriteria = (text: string): void => {
  state.searchCriteria = text;
  state.searched = true;
  console.log('setting search criteria to: ' + text)

};


export const searchStore = readonly({
  state: state,
  setters: {
    setSearchCriteria,
    setSearchType,
    setAutocompleteNameArray,
  },
  actions: {
    clearSearch: () => {
      setSearchCriteria("");
      setSearchType("location");
    },
  },
  getters: {
      /**
       * Get the current search type added by the user
       * @return string|undefined
       */
    searchType: computed(() => state.searchType),
      /**
       * Get te current search criteria asked by the user
       *
       * @return string
       */
    searchCriteria: computed(() => state.searchCriteria),
    //coords: computed(() => state.coords),
    isStartedSearching: computed(() => state.searchCriteria != "" && state.searchType != undefined),
    isSearched: computed(() => state.searched),
    getAutocompleteNameArray: computed(() => state.autocompleteName),
  },
});

import {ENUMVoucherTypes} from "@/Interfaces/EnumVouchers";

declare const google: any;
import {
    computed,
    ComputedRef,
    inject,
    nextTick,
    reactive,
    readonly,
    ref,
    Ref,
    UnwrapRef,
} from "vue";
import {MarkerClusterer} from "@googlemaps/markerclusterer";


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LatLngBounds = google.maps.LatLngBounds;


// MapCluster storage
const mapClusterRef: MarkerClusterer | undefined = undefined;

/**
 * Main Place Store
 *
 */
const state = reactive({
    google: null,
    autocomplete: null,
    boundsRef: null,
    markersRef: null,
    currentMarkersRef: null,
    mapClusterRef: mapClusterRef,
    currentClusterSizeRef: 1,
    myMapRef: null,
    myMapZoom: 9,
    myMapCenter: {lat: 50.5237, lng: 4.4579},
    myMapBounds: null,
    items: null,
    markers: null,
    findings: null,
    markerHighlight: null,
    markerSelect: null,
});

/**
 * Set the places in a correct format to the local memory and use them latter
 *
 * @param places
 */
const setPlaces = (places: Array<any>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.items = places
        .filter((element) => Number(element.lat) && Number(element.lng))
        .map((element) => {
            const element_raw = element;
            return {
                ...element_raw,
                position: {
                    lat: Number(element.lat),
                    lng: Number(element.lng),
                },
                id: element.id
            };
        });
};

const appendPlaces = (places: Array<any>) => {
        for (const pl of places) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.items.push(pl)
        }
    }
;

const getMapCluster: any = computed(
    () => state.mapClusterRef
);

const getBounds: ComputedRef<LatLngBounds | null> = computed(
    () => state.boundsRef
);

export const placeStore = readonly({
    state: state,

    setters: {
        /**
         * @param marker
         */
        setMarkerHighlight: (marker: any) => {
            state.markerHighlight = marker;
        },
         /**
         * @param marker
         */
        setMarkerSelect: (marker: any) => {
            state.markerSelect = marker;
        },
        /**
         * Store the ref to the div where is being instanced the map object
         *
         * @param zoom
         */
        setMapZoom: (zoom: number) => {
            state.myMapZoom = zoom
        },
        /**
         * Store the ref to the div where is being instanced the map object
         *
         * @param center
         */
        setMapCenter: (center: any) => {
            state.myMapCenter = center
        },
        /**
         * Store the ref to the div where is being instanced the map object
         *
         * @param bounds
         */
        setMapBounds: (bounds: any) => {
            state.myMapBounds = bounds
        },
        setPlaces,
        /**
         * Store the bounds for the current map
         * @param data
         */
        setBoundsRef: (data: LatLngBounds) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.boundsRef = data;
        },

        /**
         * Store the ref to the div where is being instanced the map object
         *
         * @param data
         */
        setGoogle: (google: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.google = google;
        },
        /**
         * Store the ref to the div where is being instanced the map object
         *
         * @param data
         */
        setGoogleAutocomplete: (autocomplete: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.autocomplete = autocomplete;
        },
        /**
         * Save a refference to the current map object
         *
         * @param map
         */
        setCurrentMap: (map: google.maps.Map) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.myMapRef = map;
        },
        /**
         * Store the MarkerClusterer instance
         * @param mc
         */
        setMarkerCluster: (mc: any) => {
            state.mapClusterRef = mc;
        },
        /**
         * Store the bounds for the current map
         * @param data
         */
        setMarkersRef: (data: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.markersRef = data;
        },
        /**
         * Store the current markers displayed on the map
         * @param data
         */
        setCurrentMarkersRef: (data: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.currentMarkersRef = data;
        },
        /**
         * Store the bounds for the current map
         * @param data
         */
        setCurrentClusterSizeRef: (data: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.currentClusterSizeRef = data;
        },

    },

    actions: {

        appendPlaces,

        /**
         * Set a reference to the map
         * @param data
         */
        makeMap: (map: google.maps.Map) => {

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.myMapRef = map;
        },
        /**
         *
         * @param searchCriteria
         */
        searchByName: async (searchCriteria: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.findings = state.items.filter((element: any) =>
                element.name.toLowerCase().includes(searchCriteria.toLowerCase())
            );
        },


        searchById: async (merchantId: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return state.items?.filter((element: any) => {
                return element.id === parseInt(merchantId);
            });
        },
        /**
         *
         */
        clearFindings: () => {
            nextTick(() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                state.items = [];
            });
        },
        /**
         * Resetting the bounds area to be used again
         */
        resetBounds: () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.boundsRef = new google.maps.LatLngBounds();
        },
    },
    getters: {
        /**
         *
         */
        getFindings: computed(() => state.findings),

        /**
         * Get all the items
         */
        getItems: computed(() => state.items),

        /**
         * Get current map object
         */
        getMap: computed(() => state.myMapRef),
        /**
         * Return all the markers in a ready way for showing the markers on the map
         */
        currentClusterSize: computed(() => state.currentClusterSizeRef),
        mapCluster: computed(() => state.mapClusterRef),
        getBoundsRef: getBounds,
        google: computed(() => state.google),
        autocomplete: computed(() => state.autocomplete),
        // cluster: getMapCluster,
        markers: computed(() => state.markersRef),
        currentMarkers: computed(() => state.currentMarkersRef),
        getMapZoom: computed(() => state.myMapZoom),
        getMapCenter: computed(() => state.myMapCenter),
        setMapBounds: computed(() => state.myMapBounds),
        getMarkerHighlight: computed(() => state.markerHighlight),
        getMarkerSelect: computed(() => state.markerSelect),
    },
});

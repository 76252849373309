import {ENUMVoucherTypes} from "@/Interfaces/EnumVouchers";
import {computed, reactive, readonly, UnwrapRef} from "vue";

const allLangs: Array<Record<string, any>> = [


    {
        label: "NL",
        value: "nl-BE",
    },
    {
        label: "FR",
        value: "fr-BE",
    },
    {
        label: "EN",
        value: "en-BE",
    },
];

const langMaps: Record<'en-BE' | 'nl-BE' | 'fr-BE', 'en' | 'fr' | 'nl'> = {
    "en-BE": "en",
    "fr-BE": "fr",
    "nl-BE": "nl",
};

const state = reactive({
    lang: "en-BE",
    languages: allLangs,
    languagesMap: langMaps,
});

/**
 *
 * @param langValue
 */
const getLangByValue = (langValue: 'en-BE' | 'nl-BE' | 'fr-BE'): 'en' | 'fr' | 'nl' => {
    if (langValue in state.languagesMap) {
        return state.languagesMap[langValue];
    }
    throw new Error("Invalid Language" + JSON.stringify(langValue));
};

export const langStore = readonly({
    setters: {
        /**
         * Set the language to the current storage
         * @param lang
         */
        setCurrentLang: (lang: string) => {
            state.lang = lang;
            return this;
        },
    },
    actions: {
        getLangByValue,
    },
    getters: {
        /**
         * Get the current language by the lang storage
         */
        getCurrentLang: computed(() => state.lang),
        languages: computed(() => state.languages),
    },
});
